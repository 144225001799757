<template>
  <v-list-item :to="{ name: to }" :disabled="disabled" link>
    <v-list-item-icon>
      <v-icon :color="color">{{ icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <slot>
        <v-list-item-title style="white-space: normal">{{
          $t(`components.layouts.main.${title}`)
        }}</v-list-item-title>
      </slot>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'MenuItem',

  props: {
    to: String,
    disabled: Boolean,
    icon: String,
    title: String,
    color: String,
  },
}
</script>
