<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app style="height: 100%">
      <v-list-item
        style="margin-bottom: -3px"
        :to="{ name: 'Home' }"
        exact
        :disabled="mustVote"
      >
        <v-list-item-content v-if="$mystore.state.event !== null">
          <v-list-item-title class="title text-wrap font-weight-black">
            {{ $mystore.state.event.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="primary--text mt-3">
            {{ $mystore.state.event.client.name }}
          </v-list-item-subtitle>
          <!-- <v-list-item-subtitle class="mt-3">
            <b v-if="$mystore.state.event.anonymous">{{
              $t('components.layouts.main.anonymous')
            }}</b>
            <b v-else>{{ $t('components.layouts.main.transparent') }}</b>
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <!-- <v-divider></v-divider> -->

      <!-- Uncomment above & remove class pt-0 bellow to enable this. -->

      <!-- <v-list dense class="pt-0"> -->
      <v-list>
        <MenuItem
          to="Questions"
          icon="mdi-information"
          title="questions"
          :disabled="mustVote"
        ></MenuItem>
        <MenuItem
          to="Communication"
          icon="mdi-forum"
          title="communication"
          :disabled="mustVote"
          v-if="
            isNotBasicUI && $mystore.state.event.enable_live_questions === true
          "
        ></MenuItem>
        <MenuItem
          to="Documents"
          icon="mdi-file"
          title="documents"
          :disabled="mustVote"
          v-if="isNotBasicUI"
        ></MenuItem>
        <MenuItem
          to="Agenda"
          icon="mdi-view-agenda"
          title="agenda"
          :disabled="mustVote"
          v-if="isNotBasicUI"
        ></MenuItem>
        <MenuItem
          to="WatchStream"
          icon="mdi-broadcast"
          :disabled="mustVote"
          title="watchStream"
          :color="$mystore.state.streams.length > 0 ? 'success' : 'error'"
          v-if="isNotBasicUI && this.$mystore.state.meetingSession !== null"
        ></MenuItem>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <p style="text-align: center">
            <a
              target="_blank"
              rel="noopener"
              href="https://fair-votes.com/live"
            >
              <img style="opacity: 1" src="@/assets/logo.png" width="120" />
            </a>
            <br />
            <small>© FairVotes.LIVE</small>
          </p>
          <v-btn block @click="$emit('logout')" class="logout-button">
            <v-icon small>mdi-logout-variant</v-icon>
            {{ $t('components.layouts.main.logout') }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- <v-app-bar app flat color="white" style="border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;"> -->
    <v-app-bar app color="white">
      <v-app-bar-nav-icon
        class="primary--text"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="font-weight-medium">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon class="mr-1" color="primary">mdi-account</v-icon>
      <span v-if="$mystore.state.voter != null">{{
        $mystore.state.voter.full_name
      }}</span>
      <template v-if="$slots.extension" v-slot:extension>
        <slot name="extension"></slot>
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid :class="containerClass">
        <slot></slot>
        <v-snackbar
          v-model="$mystore.state.snackbar"
          :timeout="5000"
          outlined
          color="primary"
        >
          {{ $mystore.state.snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="primary"
              text
              v-bind="attrs"
              @click="$mystore.closeUserNotification()"
            >
              <v-icon color="primary">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'
import has_voted from '@/helpers/has_voted.js'

export default {
  components: {
    MenuItem,
  },

  props: {
    title: String,
    noPadding: Boolean,
  },

  computed: {
    containerClass: function () {
      return this.noPadding ? 'pa-0' : ''
    },

    isNotBasicUI: function () {
      return (
        this.$mystore.state.event !== null &&
        this.$mystore.state.event.voter_ui_type !== 'basic'
      )
    },

    mustVote: function () {
      if (this.$mystore.state.activeQuestion === null) {
        return false
      }

      if (!this.hasVoted) {
        return true
      }

      return false
    },

    hasVoted() {
      if (this.$mystore.state.activeQuestion === null) {
        return false
      }

      return has_voted(this.$mystore.state.activeQuestion.voted)
    },
  },

  data() {
    return {
      drawer: null,
    }
  },
  mounted() {
    console.log(this.title)
    setTimeout(() => {
      if (
        this.$t('views.question.title') != undefined &&
        this.$t('views.question.title') != null
      ) {
        if (this.title != this.$t('views.question.title')) {
          this.title = this.$t('views.question.title')
        }
      }
    }, 3000)
  },
  watch: {
    title: {
      handler: function () {
        console.log('da')
        console.log(this.$t('views.question.title'))
      },
      deep: false,
    },
  },
}
</script>

<style>
.logout-button {
  height: auto;
  white-space: normal;
}

.logout-button span {
  flex: unset;
  font-size: 12px;
}
</style>
